/**
 * SPDX-FileCopyrightText: (c) 2000 Liferay, Inc. https://liferay.com
 * SPDX-License-Identifier: LGPL-2.1-or-later OR LicenseRef-Liferay-DXP-EULA-2.0.0-2023-06
 */

/**
 * Accepts a url and adds the cookie generated by Servlet
 * containers in the form of a jsessionid to the url.
 */
export default function getURLWithSessionId(url) {
	if (!themeDisplay.isAddSessionIdToURL()) {
		return url;
	}

	// LEP-4787

	let separatorIndex = url.indexOf(';');

	if (separatorIndex > -1) {
		return url;
	}

	const sessionId = `;jsessionid=${themeDisplay.getSessionId()}`;

	separatorIndex = url.indexOf('?');

	if (separatorIndex > -1) {
		return `${url.substring(0, separatorIndex)}${sessionId}${url.substring(
			separatorIndex
		)}`;
	}

	// In IE6, http://www.abc.com;jsessionid=XYZ does not work, but
	// http://www.abc.com/;jsessionid=XYZ does work.

	separatorIndex = url.indexOf('//');

	if (separatorIndex > -1) {
		const lastSeparatorIndex = url.lastIndexOf('/');

		if (separatorIndex + 1 === lastSeparatorIndex) {
			return `${url}/${sessionId}`;
		}
	}

	return `${url}${sessionId}`;
}
